/*Imported into index.js*/

:root {
  --primary: #dfdfdf;
  --dark: #0a1c2d;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

html, body {
 /* overflow-x: hidden;*/
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  -webkit-backdrop-filter: blur(35px);
  backdrop-filter: blur(35px);
  color: #fff;
  letter-spacing: 1.1px;
  font-weight: 400;
  line-height: 1.6;
  width: 100%;
  height: 100vh;
  background: var(--dark);
}

#App {
  height: 100%;
  width: 100%;
}

.main-container {
  width: 100%;
  position: relative;
  scroll-snap-type: y mandatory;
}

.main-container a {
  text-decoration: none;
  color: #f4f4f4;
  transition: all 400ms;
}

/*Dropdown Nav */
.menu-item {
  position: relative; 
  padding: 1rem;
  overflow-x: visible;
}

.menu-item:hover table,
.menu-item:focus table {
  opacity: 1;
  visibility: visible;
}

.nav_list_drop {
  position: absolute;
  text-align: left;
  line-height: 1em;
  background: rgba(0, 0, 0, 0.707);
  padding: 0.7rem 0;
  border-radius: 2px;
  width: auto;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  
}

table.nav_list_drop {
  border-spacing: 0px 10px;
  border-collapse: separate;
}

table.nav_list_drop a {
  cursor: pointer;
}
.nav_list_drop th, .nav_list_drop td {
  padding: 0.2rem 0.6rem 0.2rem 0.6rem;
}
.nav_list_drop th {
  border-right: solid 1px #f4f4f4;
}
.nav_list_drop td a {
  transition: all 400ms;
}

/* Bottom line animation */
.menu .menu-item > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transform: scaleX(0);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: right center;
}
.menu .menu-item > a:hover::after  {
  transform: scaleX(1);
  transform-origin: left center;
  transition-duration: 0.4s;
}


/*Sections*/
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  /*scroll-snap-align:  center;*/
  position: relative;
  overflow-x: visible;

}

/*Nie wiem czy to wogole jeszcze jest na stronie*/
.section-content {
  display: flex;
  flex-direction: row;
}

/*Longer Theory*/
.theory-content {
  line-height: 1.5rem;
  overflow-x: visible;

}


/*Theory Section
section#theory {
  justify-content: flex-start;
  overflow-x: visible;

}

section#theory .section-title{
  width: 100%;
  padding: 30px 0px 10px 0px;
}

section#theory .section-title h1 {
  font-size: 2rem;
}

section#theory .section-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  padding: 5%;
  overflow-x: visible;

}

section#theory .section-main .side-menu {
  width: 20%; 
}

section#theory .section-main .main-theory-content {
  width: 80%;
  overflow-x: visible;

}*/
section#blog {
  /*background: url("https://cdn.pixabay.com/photo/2024/03/04/16/44/barberry-8612696_1280.jpg") no-repeat
    center center/cover;*/
  position: relative;  
  overflow-x: visible;
  min-height: 100vh;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRation="none"><path fill="rgba(255, 255, 255, 0.1)" d="M0,256L1440,64L1440,320L0,320Z"></path></svg>') no-repeat center center fixed;
  background-size: cover;
}

section.simple {
  height: 100vh;
}

section .background-animation {
  position: fixed;
  top: 0;
  left: 0;
  background:  rgb(182, 182, 182);
  -webkit-filter: blur(35px);
  filter: blur(35px);
  width: 100%;
  height: 100%;
  z-index: -2;
}

div .section-paragraph {
  display: block;
  color:#0a1c2d;
  background-color: #ddd;
  width: 95%;
  margin: 1rem;
  padding: 2rem;
}

/*MainTitle*/
#main-title {
  margin-top: -80px;
}

section h1 {
  letter-spacing: 6px;
  font-weight: 400;
  font-size: 2.5rem;

}

section h1 span {
  font-size: 2rem;
}

.section-title {
  margin: auto;
}

/*TitleAddition*/
section h2 {
  letter-spacing: 5px;
  font-weight: 400;
}

section h2 span, section h3 span {
  font-size: 80%;
}

section h3 {
  letter-spacing: 3.5px;
  font-weight: 400;
}


.sm-wide-tx-r {
  font-weight: 200;/*nie działa*/
  letter-spacing: 6px;
  float: right;
}

section .page-title {
  margin-top: -100px;
  padding: 4rem;
}

section p {
  font-size: 1.5;
}

section .section-footer {
  position: absolute;
  height: 5px;
  width: 100%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.04);
}

/*Menu Subjects*/
#subjects-table {
  font-size: 3vh;
}
.subjects-title {
  padding-bottom: 10vh;
}
.subjects-menu-title-addition {
  margin-top: 2vh;
  padding: 1vh;
}
#subjects-table th{
  width: auto;
}
.subjects-container {
  display: flex;
  flex-wrap: wrap;
}

/*nie wiem gdzie to*/
.subject-title {
  padding: 2rem;
}
.subject-theory {
  display: flex;
  flex-direction: row;
}

/*Canvas*/
.background-canvas {
  background-color: #efefef; /* Set your background color or image here */
  height: 100%;
  position: relative;
}

.subject-intro-container .content {
  display: flex;
  flex: 1;
}

/*Subjects submenu*/
.subject-list {
  margin: auto 2rem;
  text-align: center;
}

.subject-list-item {
  list-style-type: none;
  display: block;
  color: var(--dark);
  margin: 0.2rem;
  padding: 0.2rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  line-height: 1.27em;
  position: relative;
  text-align: center;
  max-width: fit-content;
}

.subject-list-item::before,
.subject-list-item::after {
  width: auto; /* Set the width to auto for .subject-list-item */
}

/*Underline*/
.subject-list-item:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0px;
  background: var(--dark);
  transition: width 0.3s ease-in-out;
}

.subject-list-item:hover::after{
  width: 100%;
  
}

/*Horizontal Scroll*/
.horizontal-section {
  padding: 10% 5%;
  display: flex;
  justify-content: start;
}
.sticky-container {
  width: 100%;
  overflow: hidden;
  position: sticky; /*if I use change the position sticky to the class sticky*/
  top: 60px;
}

.element-container {
  position: relative;
  display: flex;
}

.element {
  position: relative;
  width: 70%;
  height: 500px;
  padding: 20px 30px;
  background-color: #aaa;
  margin-right: 20px;
  flex-shrink: 0;
}

/*Load Items onScroll*/
.blog .reveal {
  opacity: 0;
  transition: all 0.7s ease-in-out;
  overflow: visible;

}
.reveal.active {
  opacity: 1;
}

/*Search Bar*/
.search-main-container {
  max-width: 100%;
  /* max-width: 700px;*/
  margin: 10px auto;
  overflow: visible;
}
.search-container {
  width: auto;
  padding: 2vw;
  margin: 0 2vw;
  overflow: visible;
  position: relative;
}

.search-header {
  position: sticky;
  width: 100%;
  margin: auto;
  top: 2.6rem;
  z-index: 1995;
  display: flex;
  flex-direction: row;
}


#search {
  z-index: 3000;
  margin: 2vw 2vw;
  background: rgba(255, 255, 0, 0.2);
  backdrop-filter: blur(5px);
  color: var(--dark);
  width: 100%;
}
input[type="text"] {
  padding: 0.7em 1.2em;
  border: 1px solid #aaa;
  outline-color: #ddd;
}
.blogs{
  width: auto;
  position: relative;
  overflow: visible;
}

/* Blog Section Background */
.abstract-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -2; /* Ensures it stays behind content */
    pointer-events: none; /* Avoids interaction */
}

.content-equations {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.content-equation {
  padding: 1rem;
  max-width: 40%;
  
}

.note-equations {
  padding: 1rem;
  border-left: 1px solid #f4f4f4;
}

.more-examples {
  width: 100%;
}
.more-examples .example {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.more-examples .title {
  font-size: 0.7rem;
}

.example-left, .example-right {
  margin:1rem;
  width: auto;
}


.blog pre {
  text-align: left;
  overflow-x: visible;
}

.hidden {
  display: none;
}

/*Media Queries*/
@media only screen and (max-width: 800px) {
  .horizontal-section {
    padding: 100px 20px;
  }
  .element-container .element {
    width: 450px;
    height: 400px;
  }

  .search-container {
    justify-content: flex-start;
  }

  .search-header {
    display: flex !important;
    justify-content: flex-start;
    text-align: start;
    margin: 0;
    padding-top: 5px;
    padding-left: 0px;;
    width: 10%;
    top: 2.2rem;
    left: 1rem;
  }

  input#search {
    margin-left: 4px;
    width: 100%;
  }

  .search-header, input#search {
    transition: width 0.3s ease-in-out;

  }

  .search-header:focus-within {
    width: 90%; /* Expand search header when input is focused */
}
   input#search:focus {
    width: 90%;
    
  }
  
}
@media only screen and (max-width: 400px) {
  .horizontal-section {
    padding: 50px 15px;
  }
  .element-container .element {
    width: 200px;
  }
}
 
/*
@media only screen and (max-width: 400px) {
  .menu .menu-item > a {
    font-size: 10px;
    color: rgb(83, 83, 200);
  }


}*/