
.accordion-item {
}

.accordion-title {
    border: 1px solid #ccc;
    padding: 10px 25px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-title h3 {
    margin: 0;
}

.accordion-content {

}