/*Home Menu*/
#menu-buttons-container, .menu-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

#menu-buttons-container {
    margin: 10vh 4vh 4vh 4vh;

}

.menu-section {
    margin-top: 2.5rem;
}

/*Home Menu Sections*/
.menu-section h4{
    font-family: "SUSE", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-size: 1.5rem;
}

/*Home Menu Individual Buttons*/
.menu-buttons {
    font-family: "SUSE", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
  font-style: normal;
   /* font-family: "Fasthand", cursive;*/
    font-weight: 400;      
    font-size: 2rem;
}
.menu-button {
    all: initial;
    font-family: "SUSE", sans-serif;
    font-optical-sizing: auto;
  font-style: normal;
    font-size: 2rem;
    margin: 0 ;
    padding: 0 ;
}
.menu-button a {
    color: var(--primary);
    text-decoration: none;
    margin: 0vh;
    padding: 0 1.5vh;
    color: rgb(83, 132, 200);

}

/*Media Querries*/

@media only screen and (max-width: 445px) {
    .menu-button a{
        font-size: 1.5rem;
      }
      #menu-buttons-container {
        margin: 2vh;
    
      }
    
}  

@media only screen and (min-width: 445px) {
    .menu-button a {
        font-size: 1.7rem;
    } 
    #menu-buttons-container {
        margin: 2vh;
    
      }
}

@media only screen and (min-width: 845px) {
    .menu-button a {
        font-size: 2rem;
    } 
    .menu-button {
        margin: 0.6rem;
    }
    #menu-buttons-container {
        margin: 5vh;
    
      }
}

@media only screen and (min-width: 1200px) {
    .menu-button a {
        font-size: 2.2rem;
    } 
    .menu-button {
        margin: 0.8rem;
    }
    #menu-buttons-container {
        margin: 5vh;
    
      }
}

@media only screen and (min-width: 1845px) {
    .menu-button a {
        font-size: 3rem;
    } 
    .menu-button {
        margin: 1rem;
    }
    #menu-buttons-container {
        margin: 6vh;
    
      }
}

