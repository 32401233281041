.blog {
  background: rgba(188, 188, 188, 0.4);
  backdrop-filter: blur(15px);
  position: relative;
  max-width: 80vw;
  max-height: 90vw;
  overflow-y: hidden;
  margin: 20px auto;
  padding-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;  
}

.blog .post-body {
  background: rgba(52, 70, 89, 0.7);
  backdrop-filter: blur(15px);
  padding: 1.5rem 2rem;
}

.blog {
  position: relative; /**/
  width: auto;
  padding: 3vw 3vw 0.7vw 3vw;
  margin: 5vw auto;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
  color:rgb(220, 219, 219);
  border-radius: 2px;
  transition: all 0.3s ease;
}

.blog .post-body.expanded {
  max-height: none; /* Fully expand */
  overflow: visible; /* Ensure all content is shown */
}

.blog.expanded {
  max-height: none;
  overflow: visible;
}

.blog, .post-body p, .blog-post-footer h5 {
  width: auto;
  height: auto;
}

.post-body pre, .post-body .blog-paragraph {
  width: 100%;
  white-space: pre-wrap;
}

/* Post Body - Collapsible Content */
.post-body {
  max-height: 60vh; /* Restrict height for collapsed state */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.post-body.expanded {
  max-height: none; /* Allow full content when expanded */
  overflow: visible;
}

.post-body.collapsed {
  max-height: 60vh; /* Limit visible content for collapsed posts */
}

/* Expand Button - Triangle Style */
.expand-btn {
  position: absolute;
  box-sizing: border-box;
  bottom: 3px; /* Position at the bottom-right corner */
  right: 3px;
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-bottom: 40px solid #333; /* Triangle pointing down */
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 2;
}

.expand-btn::before {
    content: "...";
    color: white;
    font-weight: 700;
    font-size: 0.7rem;
    position: absolute;
    top: 20px;
    left: -23px;
    transition: content 0.3s ease;
    z-index: 3;
}

/*.expand-btn.expanded {
  border-bottom: none;
  border-top: 40px solid #333;
}*/

.expand-btn.expanded::before {
    content: '↑';
    top: 20px;
    left: -18px
}

.expand-btn:hover {
  transform: scale(1.1);
}

.blog > .post-title {
  width: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.blog p {
  margin-bottom: 1rem;
}

.codeText {
  background-color: rgba(10, 28, 45, 0.7);
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
 padding: 0.2rem 1.9rem;
}

.blog-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.blog-content table {
  display: block;
  box-sizing: border-box;
  text-align: left;
  border-spacing: 30px 10px;
  border-collapse: separate;
}


/* Blog Post Footer */
.blog-post-footer {
  padding: 1rem;
  width: auto;
  text-align: center;
}

.blog-post-footer h5 {
  font-weight: 200;
  letter-spacing: 4px;
}


/* The container for stacking images */
.blog-img-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; 
  min-height: 60vw;
  height: auto; 
  overflow: hidden;
  cursor: pointer;
}

.blog-img-container img {
  margin: 0.5rem 0;
}

.blog.has-images .blog-img-container {
  transition: max-height 0.3s;
}

/* Enable scrolling inside ONLY for expanded image posts */
.blog.expanded.has-images .blog-img-container {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.blog.expanded.has-images .blog-img-container::-webkit-scrollbar {
  display: none;
}

.blog-img {
  position: relative;
  /*top: 0;
  left: 0;*/
  
  
  object-fit: contain; /* Keeps aspect ratio */
  max-height: 100%;
  max-width: 100%;
  transition: transform 0.3s ease;
  z-index: 1;
}

/* Shift each blog down and slightly to the right 
.blog-img:nth-child(2){
  transform: translateX(20px);
}

.blog-img:nth-child(3) {
  transform: translateX(40px);
}
.blog-img:nth-child(4) {
  transform: translateX(60px);
}
*/
.blog-img img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

