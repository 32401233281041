.tile {
    width: auto;
    border: 1px solid #ccc;
    margin: 0px;
    padding: 10px 25px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.tile h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.tile p {
    font-size: 14px;
    color: rgba(102, 102, 102, 0.8);
}

.tile a {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background: #263a4b;
    color: #fff;
    text-decoration: none;
}

.tile a::after {
    background: linear-gradient(0.35turn, #3f87a6, #ebf8e1, #f69d3c);
    background-size: 200% 200%;
    position: absolute;
    content: "";
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    
}

.tile a:hover::after {
    animation: circle 1s ease-in-out infinite;

}

@keyframes circle {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}