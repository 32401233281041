/*Menu Section */
#section-menu {
    display: flex;
    flex-direction: row;
    
}

.section-menu .section-menu-item {
    margin: 2rem;
    width: auto;
    transition: all 500ms ease-in-out;
}

.section-menu .section-menu-item a {
    padding: 2px 10px;
    text-decoration: none;
    color: var(--primary);
    letter-spacing: 3px;
    font-size: 1.2rem;
}

.section-menu .section-menu-item:hover {
    background-color: rgba(10, 28, 45, 0.3);
    box-shadow: 0 0 3px 3px rgba(10, 28, 45, 0.3); /* Efekt rozmytych krawędzi */
}

/* Accordion Section */
.accordion-container {
    width: 100%;
}

.accordion-main > .accordion-title {
    background-color: rgba(245, 245, 245, 0.2);

}

.tile-grid {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
}

/* Media Queries */
@media only screen and (max-width: 800px) {
    #section-menu {
        flex-direction: column;
    }
}